// src/PasswordProtection.js
import React, { useState } from 'react';
import './App.css'; // Optional for styling

const correctPassword = 'jpr'; // Replace with your desired password

const PasswordProtection = ({ onAuthenticated }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === correctPassword) {
      onAuthenticated();
    } else {
      setError('Password incorreta');
    }
  };

  return (
    <div className="password-protection">
      <form onSubmit={handleSubmit}>
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </label>
        <button button className="button-33 button-submit3" type="submit">Entrar</button>
        {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
};

export default PasswordProtection;
