import React, { useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { format } from "date-fns";
import "./App.css";
import "./Transitions.css";

Modal.setAppElement("#root");

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "320px",
    padding: "30px",
    textAlign: "center",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    border: "none",
  },
};

const PatientList = ({
  filter,
  fetchPatients,
  openModal,
  patients = [],
  selectedDoctor,
}) => {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [note, setNote] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [patientToDelete, setPatientToDelete] = useState(null);
  const [actionModalIsOpen, setActionModalIsOpen] = useState(false);

  const handleStatusChange = async (id, status, callNotes = "") => {
    try {
      await axios.put(`https://backendlab.vercel.app/patients/${id}`, {
        status,
        callNotes,
        updatedAt: new Date(),
      });
      fetchPatients(); // Refresh patient list
    } catch (error) {
      console.error("Error updating patient status:", error);
    }
  };

  const handleRevertToNeedReady = async (id) => {
    try {
      await axios.put(
        `https://backendlab.vercel.app/patients/${id}/revertToNeedReady`
      );
      fetchPatients();
    } catch (error) {
      console.error("Error reverting patient to needReady:", error);
    }
  };

  const openNoteModal = (patient) => {
    setSelectedPatient(patient);
    setModalIsOpen(true);
  };

  const handleNoteSubmit = async () => {
    if (selectedPatient) {
      await handleStatusChange(selectedPatient._id, "readyToCall", note);
      setModalIsOpen(false);
      setNote("");
      fetchPatients(); // Refresh patient list after saving notes
    }
  };

  const openNotesDisplayModal = (patient) => {
    setSelectedPatient(patient);
    setShowNotesModal(true);
  };

  const closeNotesDisplayModal = () => {
    setShowNotesModal(false);
    setSelectedPatient(null);
  };

  const handlePatientClick = (patient) => {
    openModal(patient);
  };

  const openActionModal = (patient) => {
    setSelectedPatient(patient);
    setActionModalIsOpen(true);
  };

  const getCardClassName = (patient) => {
    if (filter === "needReady") {
      if (isDueSoon(patient.dueDate) && patient.laboratorio == "externo") {
        return "half-blue-half-red"; // Apply half/half background if conditions are met
      }
      if (patient.laboratorio === "externo") {
        return "green-background"; // Apply green background
      }

      if (isDueSoon(patient.dueDate)) {
        return "due-soon"; // Original class for due soon (red)
      }
    }
    if (filter === "readyToCall" && hasNotes(patient)) {
      return "has-notes"; // Apply yellow background if patient has notes
    }
    return "";
  };

  const handleMarkAsCalled = async (patient) => {
    await handleStatusChange(patient._id, "called");
    setActionModalIsOpen(false);
  };

  const handleMarkAsReadyToCall = async (patient) => {
    await handleStatusChange(patient._id, "readyToCall");
  };

  const handleCallButNotReady = async (patient) => {
    openNoteModal(patient);
  };

  const handleMarkAsCompleted = async (patient) => {
    await handleStatusChange(patient._id, "completed");
  };

  const handleDeletePatient = async (id) => {
    try {
      await axios.delete(`https://backendlab.vercel.app/patients/${id}`);
      fetchPatients();
      setConfirmationModalIsOpen(false);
      setPatientToDelete(null);
    } catch (error) {
      console.error("Error deleting patient:", error);
    }
  };

  const openDeleteConfirmationModal = (patient) => {
    setPatientToDelete(patient);
    setConfirmationModalIsOpen(true);
  };

  const closeDeleteConfirmationModal = () => {
    setConfirmationModalIsOpen(false);
    setPatientToDelete(null);
  };

  const formatDate = (date, includeTime = false) => {
    if (!date) return "";
    const options = includeTime
      ? {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }
      : { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(date).toLocaleDateString("en-GB", options);
  };

  const formatColocadoTime = (date) => {
    if (!date) return "N/A";
    return format(new Date(date), "dd/MM/yyyy HH:mm");
  };

  const isDueSoon = (dueDate) => {
    if (!dueDate) return false;
    const today = new Date();
    const twoDaysLater = new Date();
    twoDaysLater.setDate(today.getDate() + 2);
    return new Date(dueDate) <= twoDaysLater;
  };

  const hasNotes = (patient) =>
    patient.callNotes && patient.callNotes.trim() !== "";

  const getStatusText = (status) => {
    switch (status) {
      case "notYetFilledOut":
        return "Por Preencher";
      case "needReady":
        return "A executar";
      case "readyToCall":
        return "Precisa Chamada";
      case "called":
        return "Foram chamados";
      case "completed":
        return "Trabalhos Colocados";
      default:
        return status;
    }
  };

  const getStatusBorderColor = (status) => {
    switch (status) {
      case "notYetFilledOut":
        return "red";
      case "needReady":
        return "blue";
      case "readyToCall":
        return "orange";
      case "called":
        return "green";
      case "completed":
        return "purple";
      default:
        return "gray";
    }
  };

  const filteredPatients = patients.filter((patient) => {
    // Filter by status if filter is not "filterAll"
    if (filter !== "filterAll" && patient.status !== filter) return false;
    // Filter by doctor if selectedDoctor is not "Todos"
    if (selectedDoctor !== "Todos" && patient.doctorName !== selectedDoctor)
      return false;
    return true;
  });

  return (
    <div className="patient-list-container">
      <TransitionGroup className="patient-list">
        {patients
          .sort(
            (a, b) =>
              new Date(b.updatedAt || b.entryTime) -
              new Date(a.updatedAt || a.entryTime)
          )
          .map((patient) => (
            <CSSTransition key={patient._id} timeout={500} classNames="patient">
              <div
                className={`patient-card ${getCardClassName(patient)}`} // Call the function here
                onClick={() => handlePatientClick(patient)}
              >
                {filter === "filterAll" && (
                  <div
                    className="status-label"
                    style={{
                      borderColor: getStatusBorderColor(patient.status),
                    }}
                  >
                    {getStatusText(patient.status)}
                  </div>
                )}
                <div className="category">
                  {filter === "filterAll" ? `${patient.name}` : patient.name}
                </div>
                {filter === "filterAll" && (
                  <>
                    <div className="category">
                      Entrada: {formatDate(patient.entryTime, true)}
                    </div>
                    <div className="category">Dr: {patient.doctorName}</div>
                  </>
                )}
                {filter === "needReady" && (
                  <>
                    <div className="category">
                      Prazo de Entrega: {formatDate(patient.dueDate)}
                    </div>
                    <div className="category">
                      Data de Entrada: {formatDate(patient.entryTime, true)}
                    </div>
                  </>
                )}
                {filter === "readyToCall" && (
                  <>
                    <div className="category">Dr: {patient.doctorName}</div>
                    <div className="category">Tipo: {patient.typeOfWork}</div>
                  </>
                )}
                {filter === "called" && (
                  <>
                    <div className="category">Dr: {patient.doctorName}</div>
                    <div className="category">
                      Asst: {patient.assistantName}
                    </div>
                    <div className="category">Tipo: {patient.typeOfWork}</div>
                  </>
                )}
                {filter === "completed" && (
                  <div className="category">
                    Colocado em: {formatColocadoTime(patient.colocadoTime)}
                  </div>
                )}
                <div className="buttons-container">
                  {filter === "needReady" && (
                    <>
                      <button
                        className="button-33 button-mark-ready"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMarkAsReadyToCall(patient);
                        }}
                      >
                        Chamar (Pronto)
                      </button>
                    </>
                  )}
                  {filter === "readyToCall" && (
                    <>
                      {patient.callNotes && patient.callNotes.trim() !== "" && (
                        <button
                          className="button-33 button-notes"
                          onClick={(e) => {
                            e.stopPropagation();
                            openNotesDisplayModal(patient);
                          }}
                        >
                          Notas
                        </button>
                      )}
                      <button
                        className="button-33 button-mark-called"
                        onClick={(e) => {
                          e.stopPropagation();
                          openActionModal(patient);
                        }}
                      >
                        Chamado?
                      </button>
                    </>
                  )}
                  {filter === "called" && (
                    <>
                      <button
                        className="button-33 button-completed"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMarkAsCompleted(patient);
                        }}
                      >
                        Completo
                      </button>
                      <button
                        className="button-33 button-revert-to-need-ready"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRevertToNeedReady(patient._id);
                        }}
                      >
                        A Executar
                      </button>
                    </>
                  )}
                  {filter === "filterAll" && (
                    <button
                      className="delete-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        openDeleteConfirmationModal(patient);
                      }}
                    />
                  )}
                </div>
              </div>
            </CSSTransition>
          ))}
      </TransitionGroup>

      {confirmationModalIsOpen && (
        <Modal
          isOpen={confirmationModalIsOpen}
          onRequestClose={closeDeleteConfirmationModal}
          style={customModalStyles}
        >
          <div className="confirmation-modal">
            <h2>Apagar paciente?</h2>
            <div className="confirmation-modal-buttons">
              <button
                className="confirm-button"
                onClick={() => handleDeletePatient(patientToDelete._id)}
              >
                Sim
              </button>
              <button
                className="cancel-button"
                onClick={closeDeleteConfirmationModal}
              >
                Cancelar
              </button>
            </div>
          </div>
        </Modal>
      )}

      {selectedPatient && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => {
            setModalIsOpen(false);
            setSelectedPatient(null);
          }}
          style={customModalStyles}
        >
          <h2>{selectedPatient.name}</h2>
          <form>{/* Your form content here */}</form>
        </Modal>
      )}
      {selectedPatient && showNotesModal && (
        <Modal
          isOpen={showNotesModal}
          onRequestClose={closeNotesDisplayModal}
          style={customModalStyles}
        >
          <h2>Notas para {selectedPatient.name}</h2>
          <p>{selectedPatient.callNotes || "Sem Notas."}</p>
          <button onClick={closeNotesDisplayModal}>Fechar</button>
        </Modal>
      )}
      {actionModalIsOpen && (
        <Modal
          isOpen={actionModalIsOpen}
          onRequestClose={() => setActionModalIsOpen(false)}
          style={customModalStyles}
        >
          <h2 className="modal-heading">{selectedPatient?.name}</h2>
          <div>
            <button
              className="modal-button"
              onClick={async () => {
                await handleMarkAsCalled(selectedPatient);
                setActionModalIsOpen(false);
              }}
            >
              Pronto
            </button>
            <button
              className="modal-button"
              onClick={() => {
                setActionModalIsOpen(false);
                setModalIsOpen(true);
              }}
            >
              Adicionar Notas
            </button>
          </div>
        </Modal>
      )}

      {selectedPatient && modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => {
            setModalIsOpen(false);
            setSelectedPatient(null);
          }}
          style={customModalStyles}
        >
          <h2 className="modal-heading">Notas para {selectedPatient.name}</h2>
          <textarea
            className="modal-textarea"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Notas aqui"
          />
          <button
            className="modal-button"
            onClick={async () => {
              await handleNoteSubmit();
              fetchPatients(); // Refresh patient list after saving
            }}
          >
            Salvar
          </button>
          <button
            className="modal-cancel-button"
            onClick={() => setModalIsOpen(false)}
          >
            Cancelar
          </button>
        </Modal>
      )}
    </div>
  );
};

export default PatientList;
