import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import PatientForm from "./PatientForm";
import PatientList from "./PatientList";
import PasswordProtection from "./PasswordProtection";
import "./App.css";
import "./Transitions.css";
import logo from "./joaoribeirologo.png";

const App = () => {
  const [filter, setFilter] = useState("filterAll");
  const [patients, setPatients] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState("Todos");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [allPatients, setAllPatients] = useState([]); // Holds all patients

  // Define the missing function
  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const fetchPatients = async () => {
    try {
      const response = await axios.get(
        "https://backendlab.vercel.app/patients"
      );
      setAllPatients(response.data); // Store all patients in a separate state

      // Filter patients based on the current filter, selected doctor, and search term
      let filteredData = response.data;

      if (filter !== "filterAll") {
        filteredData = filteredData.filter((p) => p.status === filter);
      }

      if (selectedDoctor !== "Todos") {
        filteredData = filteredData.filter(
          (p) => p.doctorName === selectedDoctor
        );
      }

      if (searchTerm.trim() !== "") {
        filteredData = filteredData.filter((p) =>
          p.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      setPatients(filteredData);
    } catch (error) {
      console.error("Error fetching patients:", error);
    }
  };

  const openModal = (patient = null) => {
    setCurrentPatient(patient);
    setModalIsOpen(true);
  };

  useEffect(() => {
    fetchPatients();
  }, [filter, startDate, endDate, selectedDoctor, searchTerm]);

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentPatient(null);
  };

  const handleAuthentication = () => {
    setAuthenticated(true);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    if (newFilter !== "completed") {
      setStartDate(null);
      setEndDate(null);
    }
  };

  if (!authenticated) {
    return <PasswordProtection onAuthenticated={handleAuthentication} />;
  }

  // Count the number of patients for each filter

  // Calculate counts based on allPatients, not patients
  const patientCounts = {
    Todos: allPatients.length,
    "A executar": allPatients.filter((p) => p.status === "needReady").length,
    "Precisa Chamada": allPatients.filter((p) => p.status === "readyToCall")
      .length,
    "Foram chamados": allPatients.filter((p) => p.status === "called").length,
    "Trabalhos Colocados": allPatients.filter((p) => p.status === "completed")
      .length,
  };
  return (
    <div className="app">
      <div className="top-container">
        <img
          src={logo}
          alt="Logo"
          className="logo"
          onClick={() => handleFilterChange("filterAll")}
        />

        <div className="filters">
          <button
            onClick={() => handleFilterChange("filterAll")}
            className={filter === "filterAll" ? "active-filter" : ""}
          >
            Todos
          </button>
          <button
            onClick={() => handleFilterChange("needReady")}
            className={filter === "needReady" ? "active-filter" : ""}
          >
            A executar
          </button>
          <button
            onClick={() => handleFilterChange("readyToCall")}
            className={filter === "readyToCall" ? "active-filter" : ""}
          >
            Precisa Chamada
          </button>
          <button
            onClick={() => handleFilterChange("called")}
            className={filter === "called" ? "active-filter" : ""}
          >
            Foram chamados
          </button>
          <button
            onClick={() => handleFilterChange("completed")}
            className={filter === "completed" ? "active-filter" : ""}
          >
            Trabalhos Colocados
          </button>
        </div>
      </div>

      <div className="search-container">
        <input
          type="text"
          placeholder="Procurar Paciente"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <button onClick={handleClearSearch}>Apagar</button>

        <select
          value={selectedDoctor}
          onChange={(e) => setSelectedDoctor(e.target.value)}
          className="doctor-filter"
        >
          <option value="Todos">--</option>
          <option value="JP">JP</option>
          <option value="Ricardo">Ricardo</option>
          <option value="João Rua">João Rua</option>
          <option value="Bernardo">Bernardo</option>
          <option value="Sónia">Sónia</option>
          <option value="Maria do Carmo">Maria do Carmo</option>
          <option value="João Pinto">João Pinto</option>
          <option value="Marta Vale">Marta Vale</option>
        </select>
      </div>

      <div className="patient-counts-container">
        <span>
          <strong>Todos:</strong> {patientCounts["Todos"]}
        </span>{" "}
        |
        <span>
          <strong>A executar:</strong> {patientCounts["A executar"]}
        </span>{" "}
        |
        <span>
          <strong>Precisa Chamada:</strong> {patientCounts["Precisa Chamada"]}
        </span>{" "}
        |
        <span>
          <strong>Foram chamados:</strong> {patientCounts["Foram chamados"]}
        </span>{" "}
        |
        <span>
          <strong>Trabalhos Colocados:</strong>{" "}
          {patientCounts["Trabalhos Colocados"]}
        </span>
      </div>

      <div className="scrollable-container">
        <PatientList
          filter={filter}
          fetchPatients={fetchPatients}
          openModal={openModal}
          patients={patients}
        />
      </div>

      <div className="add-patient-container">
        <button className="add-patient-button" onClick={() => openModal()}>
          +
        </button>
      </div>

      <PatientForm
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        fetchPatients={fetchPatients}
        initialData={currentPatient}
      />
    </div>
  );
};

export default App;
